/*============ header ============*/
header.top {
    font-family: var(--font-primary);
}

.header-bar-top {
    display:flex;

    color: var(--green);
    font-weight: 700;
    flex-wrap: wrap;
    .logo-wrapper{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-right: 60px;
        img{
            width: 303px;
        }
        .site-name{
            margin:0 2rem;
            font-size: 30px;
        }
    }
    @media screen and (max-width:768px) {
        flex-direction: column;
        align-items: flex-start;
        .logo-wrapper{
            flex-direction: column;
            margin:0 auto;
            padding:.5rem;
            .site-name{
                padding:0;
                margin:0;
            }
        }
      
    }
    @media screen and (max-width:420px) {
        .site-name{
            display: none;
        }
        .logo-wrapper{
            padding:.5rem;
        }
    }
   
}
.header-links{
    height: 65px;
    display: flex;
    align-items: center;
    margin:0 1rem;
    margin-left:auto;
    gap:2rem;
    font-size: clamp(17px, 3.25vw, 20px);
   
    @media screen and (max-width:768px) {
        display: none;
    }
    a{
        transition: color .15s linear;
        overflow: visible;
        &:hover{
            color:var(--dark-blue);
        }
        &.site-link img{
            transition: transform .15s linear;
            overflow: visible;
            &:hover{
                transform: scale(1.2);
                filter: brightness(0) invert(11%)  sepia(20%) saturate(1915%) hue-rotate(-213deg) brightness(93%) contrast(104%);
            }
            
        }
    }

}
header.top li {
    vertical-align:top;
}
.header-menu {
 
}
.header-menu > ul {
    text-align:right;
}
.header-menu li {
    margin:20px;
}
.header-bar-bottom {
    background:var(--dark-blue);
    color:#fff;
    position:relative;
    display: flex;
    .main-menu{
        padding:0 2rem;
        margin-left:auto;
        display: flex;
        font-size: clamp(16px, 3vw, 18px);
        li {
            margin:0 2rem;
            line-height:1;
        }
    }
}
.main-menu ul {
    text-align:justify;
    line-height:0;
}
.main-menu ul::after {
    content:"";
    display:inline-block;
    width:100%;
    line-height:0px;
    vertical-align:top;
}

.main-menu li.depth-0 {
    vertical-align:middle;
}

.main-menu a {
    padding:20px 0;
}
.main-menu li > ul {
    position:absolute;
    top: 100%;
    background-color: var(--white);

    color:#082133;
    z-index:10000;
    text-align: left;
    display:none;

    flex-direction: column;
    border-right: 1px solid #dedede;
    border-bottom: 1px solid #dedede;

    
    & > li{
        // flex: 1 1 0;
        text-align: center;
        height: 100%;
        // border-left: 4px solid var(--green);
    }
}
.main-menu li:hover > ul {
    display:flex;
}
.main-menu li > ul::before,
.main-menu li > ul::after {
    content:"";
    // background:#f6f6f6;
    position:absolute;
    top:0;
    bottom:0;
    width:0;
    height:100%;
}
.main-menu li > ul::before {
    left:0;
    padding-left:50vw;
    margin-left:-50vw;
    // background:#f6f6f6;
}
.main-menu li > ul::after {
    right:0;
    padding-right:50vw;
    margin-right:-50vw;
    // background:#f6f6f6;
}
.main-menu .depth-1 .title {
    font-size:18px;
}
.header-bar-bottom{
    .main-menu .depth-1{
        margin:0;
    }
} 
.main-menu .depth-1 a {
    border-left: 5px solid var(--green);
    text-align: left;
    &:hover{
        background-color: var(--orange);
        color: var(--white);  
        border-left:5px solid transparent;
    }
    margin:0;
    padding:20px;
}