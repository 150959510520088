:root {
    //color
    --black: #434343;
    --white: #ffffff;
    --green: #008165;
    --orange: #F78E1E;
    --dark-blue: #001F29;
    //font style
    --font-primary: proxima-nova, sans-serif;;


    //font color
    --color-primary: #434343;
}

/* -- Font size list --
font-size: clamp(24px, 4.25vw, 54px);
font-size: clamp(20px, 4vw, 30px);
font-size: clamp(18px, 3.5vw, 22px);
font-size: clamp(17px, 3.25vw, 20px);
font-size: clamp(16px, 3vw, 18px);
font-size: 16px
*/