.t-product{
    .subtemplate-product-list-block, .page-icon{
        margin-top: -8rem;
        padding:0;
        &::after{
            display:none;
        }
        @media screen and (max-width:998px) {
            margin-top: -6rem;
        }
        @media screen and (max-width:576px) {
            margin-top: -4rem;
        }
    }
    .page-icon{
        background-color: var(--green);
        display: inline-block;
    }
    .gallery.kmsgallery.display-default{
        margin-top:6rem;
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(3, 1fr);
        .kmsgallery-thumbnail-image{
            width: 100%;
            height: auto;
            aspect-ratio: 1/1;
            object-fit: cover;
            margin:0;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            
            &::after{
                content:"";
                top:0;
                left:0;
                right:0;
                bottom:0;
                position: absolute;
                background-color: #434343;
                opacity: 0;
                mix-blend-mode: multiply;
                transition: opacity .3s ease-in-out;
                z-index: 1;
            }
            &::before{
                content: "View";
                z-index: 10;
                position: absolute;
                background-color: var(--orange);
                color: var(--white);
                border-radius: 60px;
                transition: opacity .3s ease-in-out;
                opacity: 0;
                font-size: clamp(17px, 3.25vw, 20px);
                padding:.5rem 2rem;
                width:200px;
                text-align: center;
            }
            &:hover{
                &::after{
                    opacity: .9;
                }
                &::before{
                    opacity: 1;
                }
            }
        }
        @media screen and (max-width:768px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width:500px) {
            grid-template-columns: 1fr;
        }
    }
}