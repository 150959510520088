/*============ sub page============*/
.page-content {
    padding:5px;
}
.splash{
    position: relative;
    display: flex;
    align-items: center;
    padding-top:max(35vw,300px);
    .page-title{
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        z-index: 10;
        color: var(--white);
        display: flex;
        align-items: flex-start;
        font-size: clamp(24px, 4.25vw, 54px);
        flex-direction: column;
        justify-content: center;
        .text-orange{
            font-size: clamp(20px, 4vw, 30px);
        }
    }
    &.no-graphic{
        background-color: var(--black);
    }
}


.page-graphic {
    &::after{
        content:"";
        top:0;
        left:0;
        right:0;
        bottom:0;
        position: absolute;
        background-color: #C8C8C8;
        mix-blend-mode: multiply;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100% !important;
        object-fit: cover;
    }
}
.floating-div-wrapper{
    padding:0 20px;
    transform: translateY(-50%);
    position: relative;
    z-index: 10;
    max-height: 0;
    &.header{
     
        .floating-div{
            font-weight: 700;
            h4{
                text-align: center;
                padding: clamp(.75rem, 3vw, 2rem) 2rem;
                margin:0;
            }
           
        }
    }
    &.footer{
        transform: translateY(50%);
        .recaptcha-notice{
            display: none;
        }
        .floating-div{
            
            form{
                display: flex;
                align-items: center;
                gap:2rem;
                padding:2rem 4rem;
            }
        
           
            .js-float-wrap{
                flex-grow: 1;
                margin:0;
            }
            label.float{
                color:white;
            }
            button{
                background-color: transparent;
                font-size: clamp(20px, 4vw, 30px);
                border: none;
                margin:0;
                padding:0;
                color: var(--blue);
    
            }
            h4{
                margin:0;
         
            }
            @media screen and (max-width:976px) {
                // border-radius: 30px;
                form{
                    flex-wrap: wrap;
                    justify-content: center;
                    padding:2rem;
                    
                }
                
                h4{
                    width: 100%;
                    text-align: center;
                }
            }
        
        };
    }
}
.floating-div{
    background-color: var(--orange);
    color: var(--dark-blue);
    border-radius: 60px;
    max-width: 1200px;
    margin: 0 auto;
    font-size: clamp(20px, 4vw, 30px);
    min-height: fit-content;
    display: flex;
    justify-content: center; 
    align-items: center;
    transform: translateY(-50%);
}
@media screen and (max-width:976px) {
    .floating-div-wrapper{
        padding:0;
        transform: translateY(0)!important;
    }
    .floating-div{
        border-radius: 0;

    }

}
.before-footer{
    // margin-bottom: -2.5rem;
    @media screen and (max-width:500px) {
        margin-bottom: 0;
    }
}
.splide{
    .image-wrapper{
        display: flex;
        justify-content: center;
    }
    .splide__arrow{
        background-color: transparent;
        svg{
            height: 18px;
            width: 12px;
        }
    }
}
.page-body{
    font-size: clamp(17px, 3.25vw, 20px);
}
.page-subtitle{
    margin-top: 3rem;
    padding-bottom: 0;
    font-size: clamp(24px, 4.25vw, 54px);
    font-weight: 700;
    color: var(--green);
}