html {
    color: #222;
    font-size: 1em;
    line-height: 1.4;
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
input,
select,
textarea,
button {
    border-radius:0; /* reset safari round corners */
}


/* ==========================================================================
   Author's custom styles
   ========================================================================== */

body {
    padding:0;
    margin:0;
    font-size:12px;
}

.contentwrap {
    max-width:1200px;
    margin: 0 auto;
    position:relative;
    padding: 20px;
}
header .contentwrap,
footer .contentwrap {
    padding: 0 20px;
}

img {
    border:none;
}

.pager{
    list-style: none;
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    gap:.75rem;
    li{
        margin:5px;
        border: 2px solid var(--green);
        background-color: transparent;
        a{
            display: block;
            padding:.5rem .75rem;
            color:var(--black);
            text-decoration: none;
        }
        &:hover, &.active{
            a{
                color:var(--white);
            }

            background-color: var(--green);
        }
        &.disabled{
            cursor:not-allowed;
            pointer-events: none;
            border-color: #ccc;
            span{
                display: block;
                padding:.5rem .75rem;
            }

        }
    }
}
// a.logo {
//     display: inline-block;
//     width: 250px;
//     height:auto;
// }
header a,
footer a,
.contact-details a {
    text-decoration: none;
    color: inherit;
}

@import '@splidejs/splide/css';
@import "vars";
@import "menus";
@import "forms";
@import "site_links";
@import "header";
@import "mobile_menu";
@import "footer";
@import "index";
@import "page";
@import "contact";
@import "block";
@import "product";
@import "news";

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 960px) {
    #alerts {
        padding-right:60px;
    }
}
@media only screen and (max-width: 760px) {

    a {

        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;

    }
/** form **/
    .contact-details.has-map,
    .map-wrap{
        width: 100%;
        padding: 0px;
    }
    .map-wrap {
        margin-top: 20px;
        height: 80vh;
    }
    #map-click {
        display: block;
    }
}
@import "helpers";
@import "print";