.subtemplate-product-list-block{

    position: relative;
    padding:2rem 0;
    &::after{
        background-color: #F1F1F1;
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:15%;
        content:"";
        display: block;
        z-index: -1;
    }
    .block-title{
        text-align: center;
        color: var(--dark-blue);
        font-weight: 600;
        font-size: clamp(20px, 4vw, 30px);
    }
    .product-list-wrapper{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap:1rem;
        a{
            background-color: var(--green);
            transition: background-color .2s linear;
            img{
                width:100%;
                aspect-ratio: 1/1;
            }
            &:hover{
                background-color: var(--orange);
            }
        }
        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media screen and (max-width: 576px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width: 300px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
  
}


.subtemplate-content-image, .subtemplate-image-content{
    padding:4rem 0;
    .contentwrap{
        display: flex;
        gap:1rem;
        flex-direction: row-reverse;
       
        & > *{
            flex: 1 1 0;
        }
        .block-title{
            margin:0;
            font-size: clamp(20px, 4vw, 30px);
        }
        .block-content{
            // font-weight: 600;
            font-size: clamp(20px, 4vw, 30px);
            a.button{
                margin-top: 2rem;
            }
     
        }
        .block-icon{
            text-align: right;
            img{
                width: 80%;
                aspect-ratio: 1/1;
            }
        }
        @media screen and (max-width:768px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
            .block-icon{
                text-align: center;
            }
            a.button{
                width: 100%;
            }
        }
    }

}
.subtemplate-image-content{
    background-color: #F1F1F1;
    .contentwrap{
        flex-direction: row;
        .block-icon{
            text-align: left;
        }
    }
  
}
.subtemplate-content-image, .subtemplate-image-content{
    .contentwrap{
        @media screen and (max-width:768px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
            .block-icon{
                text-align: center;
            }
            a.button{
                width: 100%;
            }
        }
    }
}
.subtemplate-product-feature-block{
    position: relative;
    padding:4rem 0;
    .block-graphic{
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        z-index: -1;
        img{
            width: 100%;
            height: 100%!important;
            object-fit: cover;
        }
        &::after{
            content:"";
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            mix-blend-mode: multiply;
            background-color: #C8C8C8;
        }
        
    }
    .pre-title{
        font-size: clamp(20px, 4vw, 30px);
    }
    .block-title{
        font-size: clamp(24px, 5vw, 54px);
        margin:0;
    }
    .block-content{
        font-size: clamp(20px, 4vw, 30px);
        max-width: 50%;
    }
    @media screen and (max-width:768px) {
        .block-content{
            max-width: 100%;
        }   
    }
}