/*============ footer ============*/
footer .footer-bottom {
    background-color:var(--dark-blue);
    color:#fff;
    padding-top: 10rem;
    padding-bottom: 4rem;
    .contentwrap{
        max-width: 1400px;
    }
    @media screen and (max-width:992px) {
        padding-top: 6rem;
        padding-bottom: 1rem;
    }
}
.site-map > ul {
    display:table;
    width:100%;
}
.site-map > ul > li {
    display:table-cell;
    padding:20px 0;
}
.site-map li li {
    display:block;
    margin:10px 0;
}
.site-map a {
    color:inherit;
    text-decoration:none;
    display:block;
    padding-left:30px;
    position:relative;
    line-height:30px;
}
/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}
.bykeo a {
    color: grey;
    text-decoration:none;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    transition: transform 500ms ease;
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}

.footer-menu-wrapper{
    display:flex;
    width: 100%;
    gap:4rem 2rem;
    align-items: flex-start;
    margin-bottom: 4rem;
    justify-content: space-between;
    a.logo{
        margin-top: 0;
        margin-left: 0;
        img{
            width: 276px;
        }
    }
    @media screen and (max-width:992px) {
        flex-direction: column;
        align-items: center;
        
        a.logo{
            text-align: center;
        }
    }
}
.footer-menu > ul{
    display:flex;
    gap:2rem 4rem;
    .depth-0 > a > .title{
        font-weight: 600;
        font-size: clamp(16px, 2.5vw, 20px);
    }
    ul{
        margin-top: 1rem;
        display:flex;
        flex-direction: column;  
        font-size: 16px;
        gap:.5rem;
        text-transform: none;
    }
    .title:hover{
        // text-decoration: underline;
        border-bottom: 2px solid var(--white);
    }
    @media screen and (max-width:768px) {
        display: none;
    }
}
.footer-contact{
    a.logo{
        margin-bottom: 2rem;
        display: block;
    }
    @media screen and (max-width:992px) {
        margin-top: 4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
.footer-content{
    margin-top: 4rem;
    max-width: 700px;
    font-size: 16px;
    @media screen and (max-width:992px) {
        text-align: center;
        margin:0 auto;
        margin-top: 4rem;
    }
    @media screen and (max-width:768px) {
        margin-top: 0;
    }
}

.footer-contact-grid{
    display:inline-grid;
    grid-template-columns:auto 1fr;
    grid-gap:1.5rem 1.5rem;
    font-size: 16px;
    .icon, img{
        width:20px;
        height:20px;
        object-fit: contain;
    }
    .socials{
        grid-column:  1 / -1;
        @media screen and (max-width:500px) {
            margin-top: .5rem;
        }
        .site-links{
            display: flex;
            flex-direction: column;
            gap:1.5rem;
        }
        .site-link{
            display: flex;
            margin:0;
            width: auto;
            .social-link-icon{
                margin-right: 1.5rem;
            }
            &:hover{
                filter:none;
                font-weight: bold;
                text-decoration: underline;
            }
            @media screen and (max-width:500px) {
                flex-direction: column;
                align-items: center;
                display: flex;
                gap:1rem;
                height: auto;
                .social-link-icon{
                    margin:0;
                }
            }
        }
    }
    .tel{
        font-weight: 700;
        &:hover{
            text-decoration: underline;
        }
    }
    .email{
        &:hover{
            font-weight: bold;
            text-decoration: underline;
        }
    }
    @media screen and (max-width:500px) {
        grid-template-columns: 1fr;
        justify-items: center;
        text-align: center;
        gap:1rem;
        .icon{
            margin-top: .5rem;
        }
    }
}
.before-footer{
    .horizontal-list{
        margin-top: 1rem;
        margin-bottom: 3rem;
        gap:.5rem;
        li{
            background-color: var(--green);
            &:hover{
                background-color: var(--orange);
            }
        }
    }
}