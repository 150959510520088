/*============ splash ============*/
/*  banner */
.banners-home{
    position: relative;
}
.banners-home .banner-stage {
    padding-top:max(40%, 300px);
    position:relative;
    background-color: var(--dark-blue);
}
.banners-home .banner {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display:flex;
    background-position:center;
    background-size:cover;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    text-decoration: none;
    background-color: #C8C8C8;
    background-blend-mode: multiply;
    &.active{
        z-index: 1;
        opacity: 1;
    }

    .banner-content{
        display:flex;
        flex-direction: column;
        width: 100%;
        max-width: 1200px;
        padding:20px;
        margin:0 auto;
        position:absolute;
        top: 25%;
        left: 0;
        right: 0;
        bottom:0;
        flex-wrap: wrap;
        padding-bottom: 5rem;
        .banner-name{
            display: none;
        }
        .banner-title{
            color:white;
            font-size: clamp(24px, 5vw, 54px);
            width: min(50vw, 550px);
            font-weight: 700;
        }
    }
    &[href]{
        .banner-content::after{
            content:"Read more";
            color: white;
            background-color: var(--orange);
            font-size: clamp(17px, 3.25vw, 20px);
            border-radius: 60px;
            display: block;
            width: fit-content;
            padding:1rem 4rem;
            margin-top: 2rem;
        }
    }
    @media screen and (max-width:500px) {
        .banner-content .banner-title{
            width: 100%;
        }
        &[href] .banner-content::after{
            display: none;
        }
    }
}
.banners-home .banner-navigation {
    display:flex;
    justify-content: center;
    position: absolute;
    top:15%;
    left:0;
    right: 0;
    z-index: 5;
    .banner-navigation-body{
        display:flex;
        padding:20px;
        gap:1rem;
        width: 100%;
        max-width: 1200px;
        margin:0 auto;
        .banner-nav-item{
            cursor:pointer;
            border:solid white 1px;
            border-radius: 50%;
            height: 15px;
            width: 15px;
            &.active{
                background-color: white;
            }
        }
        .banner-name, .banner-title{
            display: none;
        }
    }
}
