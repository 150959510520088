
/** form generic **/
form {
    width: 100%;
    box-sizing: border-box;
}
form p {
    position: relative;
    margin: 5px 0;
}
label.float,
input {
    height: 40px;
    line-height: 40px;
}
label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
}
input,
textarea {
    text-align: left;
    padding-left: 20px;
    font-family: var(--font-primary);
    font-size: 16px;
    padding-top: 15px;
    height: 58px;
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    border:1px solid var(--dark-blue);
}
textarea {
    min-height: 80px;
}
label.float {
    position: absolute;
    left:0;
    color: inherit;
    padding-left: 20px;
    max-width: 100%;
    box-sizing: border-box;
    transition: all .5s ease;
    z-index: 1;
    line-height: 58px;
    font-size: clamp(17px, 3.25vw, 20px);
}
.js-float-wrap {
    position:relative;
}
.js-float-wrap.focused label.float,
.js-float-wrap.populated label.float {
    left: 5px;
    padding-top:5px;
    padding-left: 5px;
    line-height: 14px;
    font-size: 14px;
}
