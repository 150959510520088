.t-news{
    .no-results{
        font-size: clamp(20px, 4vw, 30px);
        color: var(--green);
        font-weight: 400;
    }
    .news-wrapper{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap:4rem 1rem;
        @media screen and (max-width:500px) {
            grid-template-columns: 1fr;
        }
        a{
            text-decoration: none;
            color: inherit;
        }
    }
    .news-item{
      
        display: flex;
        flex-direction: column;
        // align-items: center;
        font-size: clamp(17px, 3.25vw, 20px);
        .news-icon{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width:100%;
                aspect-ratio: 4/3;
                object-fit: cover;
            }
            &::after{
                content:"";
                top:0;
                left:0;
                right:0;
                bottom:0;
                position: absolute;
                background-color: #434343;
                opacity: 0;
                mix-blend-mode: multiply;
                transition: opacity .3s ease-in-out;
                z-index: 1;
            }
            &::before{
                content: "View";
                z-index: 10;
                position: absolute;
                background-color: var(--orange);
                color: var(--white);
                border-radius: 60px;
                transition: opacity .3s ease-in-out;
                opacity: 0;
                font-size: clamp(17px, 3.25vw, 20px);
                padding:.5rem 2rem;
                width:200px;
                text-align: center;
            }
            &:hover{
                &::after{
                    opacity: .9;
                }
                &::before{
                    opacity: 1;
                }
            }
        }
        .news-title{
            font-size: clamp(18px, 3.5vw, 22px);
            font-weight: 700;
            color:var(--orange);
            margin-bottom: 0;
        }
        .news-summary{
            margin-bottom: 10px;
        }
        .news-link{
            color:var(--orange);
            transition: font-weight .15s linear;
            &:hover{
                font-weight: 700;
            }
        }
    }

    .more-news{
        margin-top:4rem;
        margin-bottom: 12rem;
        .row{
            align-items: center;
            gap: 3rem;
            margin-bottom: 2rem;
            .title{
                color: var(--orange);
                font-weight: 600;
                font-size: clamp(20px, 4vw, 30px);
            }
        }
       
        .two-col{
            grid-gap:1rem;
        }

        a{
            text-decoration: none;
            color: var(--white);
            font-size: clamp(17px, 3.25vw, 20px);
            .news-title{
                font-size: clamp(18px, 3.5vw, 22px);
                font-weight: 700;
                color:var(--green);
                transition: color .15s linear;
            }
            .news-summary{
                margin-bottom:0;
            }
            .news-link{
                margin:5px 0;
                color:var(--green);
                transition: color .15s linear;
            }
            .img-wrapper{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 100%;
                }
                &::after{
                    content:"";
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    position: absolute;
                    background-color: #434343;
                    opacity: 0;
                    mix-blend-mode: multiply;
                    transition: opacity .3s ease-in-out;
                    z-index: 1;
                }
                &::before{
                    content: "View";
                    z-index: 10;
                    position: absolute;
                    background-color: var(--orange);
                    color: var(--white);
                    border-radius: 60px;
                    transition: opacity .3s ease-in-out;
                    opacity: 0;
                    font-size: clamp(17px, 3.25vw, 20px);
                    padding:.5rem 2rem;
                    width:200px;
                    text-align: center;
                }
                
            }
            &:hover{
                .img-wrapper::after{
                    opacity: .9;
                }
                .img-wrapper::before{
                    opacity: 1;
                }
                .news-title, .news-link{
                    color:var(--orange)
                }
            }
        }
       
        
       
       
    }
}