
/*============ Contact ============*/

.map-wrap {
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
    box-sizing: border-box;
}
.contact-details.has-map {
    padding-right: 10px;
}

.contact-details {
    form{
        display: flex;
        flex-direction: column;
        gap:.75rem;
        .js-float-wrap{
            margin:0;
            color:#C8C8C8;
            &.focused, .populated{
                label{
                    color: var(--green);
                    font-size:14px;
                }
                input, textarea{
                    outline-color: var(--green);
                }
            }
        }
        button[type=submit]{
            align-self: flex-start;
            justify-self: flex-start;
            width: fit-content;
            &:hover{
                // transform: none;
                background-color: var(--green);
               
            }
        }

        input, textarea{
            border: 1px solid #C8C8C8;
        }
        textarea{
            height: 200px;
        }
    }
   
}
.opening-hours {
    display: block;
}
.contact-wrapper form {
    // padding-top: 20px;
}
.contact-wrapper form p {
    // margin: 10px 0;
}

.map-wrap {
    position: relative;
    background-color: #d3d3d3;
    padding-top: 280px;
}
.kms-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}
#map,
#map-click {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#map {
    display: block;
}
.t-contact{
    .form-response{
        text-align: center;
        padding:20px;
        color: var(--black);
        font-size: clamp(20px, 4vw, 30px);
        #form-result{
            color: var(--green);  
        }
    }
    .page-subtitle{
        color: var(--orange);
        padding:0;
        font-weight: 600;
        font-size: clamp(20px, 4vw, 30px);
    }
    .two-col{
      
        grid-gap:1rem;
    }
    .contact-grid{
        display: inline-grid;
        grid-template-columns: auto 1fr;
        grid-gap: 1.5rem 1.5rem;
        padding-top:2rem;
        padding-bottom:2rem;
        font-size: 16px;
        align-self: center;
        color: var(--green);
        .icon{
            filter:brightness(0) saturate(100%) invert(30%) sepia(77%) saturate(6393%) hue-rotate(161deg) brightness(93%) contrast(103%);
        }
        .icon, img{
            width:20px;
            height:20px;
            object-fit: contain;
        }
        .socials{
            grid-column:  1 / -1;
            @media screen and (max-width:500px) {
                margin-top: .5rem;
            }
            .site-links{
                display: flex;
                flex-direction: column;
                gap:1.5rem;
            }
            .site-link{
                display: flex;
                margin:0;
                width: auto;
                .social-link-icon{
                    margin-right: 1.5rem;
                }
                &:hover{
                    filter:none;
                    font-weight: bold;
                    text-decoration: underline;
                }
                @media screen and (max-width:500px) {
                    flex-direction: column;
                    align-items: center;
                    display: flex;
                    gap:1rem;
                    height: auto;
                    .social-link-icon{
                        margin:0;
                    }
                }
            }
        }
        .tel{
            font-weight: 700;
            &:hover{
                text-decoration: underline;
            }
        }
        .email{
            font-weight: 700;
            &:hover{
                font-weight: bold;
                text-decoration: underline;
            }
        }
        @media screen and (max-width:500px) {
            grid-template-columns: 1fr;
            justify-items: center;
            text-align: center;
            gap:1rem;
            .icon{
                margin-top: .5rem;
            }
        }
    }
    .form-wrapper{
        padding-top:3rem;
        // @media screen and (max-width:768px) {
        //     .map-wrap{
        //         grid-row: 1;
        //     }
        // }
        
    }
}